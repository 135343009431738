<template>
  <v-row class="align-center justify-center">
    <v-col cols="4">
      <p class="caption">À propos de SLODiM</p>
      <v-card tile class="ma-auto">
        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon color="grey">mdi-laptop</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Développé par</v-list-item-subtitle>
            <v-list-item-title>INRIA Nancy - Équipe Sémagramme</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon color="grey">mdi-cogs</v-icon>
          </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Version</v-list-item-subtitle>
              <v-list-item-title>{{ info.commit }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <p class="text-right mt-5">
          <v-btn text color="primary" @click="$router.go(-1)">Retour</v-btn>
        </p>
    </v-col>
  </v-row>
</template>

<script>

import MixinInfo from '@/mixins/info';

export default {
  name: 'Info',
  mixins: [MixinInfo],
  data: () => ({
    info: [],
  }),

  async mounted() {
    this.info = await this.getInfo();
  },
};

</script>
