import MixinRequest from './request';

export default {
  mixins: [MixinRequest],
  methods: {

    async getInfo() {
      const response = await this.get(`${this.apiRoot}/info`);

      if (response.status !== 200) {
        const data = await response.text();
        return { commit: data };
      }

      const data = await response.json();
      return data;
    },

    async getSettings() {
      const response = await this.get(`${this.apiRoot}/info/settings`);
      if (response.status !== 200) {
        const data = await response.text();
        return { error: data };
      }

      const data = await response.json();

      return data;
    },
  },
};
